<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                    class="text-h5"
                >Creazione Permesso
                    <v-spacer></v-spacer>
                    <v-btn 
                        icon
                        @click="cancelMethod"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                
                    <v-stepper v-model="progress" elevation="0">
                        <v-stepper-header>
                            <v-stepper-step
                                :complete="progress > 1"
                                step="1"
                            >
                                Compilazione
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="2">
                                Conferma
                            </v-stepper-step>
                        </v-stepper-header>

                    <v-stepper-items>

                    <v-stepper-content step="1" class="ma-0 pa-0">
                        <v-container fluid>
                            <v-row no-gutters class="d-flex justify-center">
                                <v-radio-group v-model="timeInputKind" class="pa-0 d-flex" dense row>
                                    <v-radio label="Automatico" value="auto" class="" :disabled="!enableCoverMorningTimes && !enableCoverAfternoonTimes"/>
                                    <v-radio label="Manuale" value="manual" class=""/>
                                </v-radio-group>
                               
                            </v-row>
                            <v-row v-if="timeInputKind == 'manual'" class="d-flex justify-center">
                                <v-form ref="form">
                                    <v-container fluid>
                                        <v-row no-gutters class="px-12">
                                            
                                                <v-text-field
                                                    class="ml-2 mr-2"
                                                    type="number"
                                                    label="Ore"
                                                    outlined
                                                    v-model="manualHours"
                                                />
                                                <v-text-field
                                                    class="ml-2 mr-2"
                                                    type="number"
                                                    label="Min"
                                                    outlined
                                                    hint="Step minimo: 30 minuti"
                                                    v-model="manualMinutes"
                                                    :rules="[minuteValueCheckRule]"
                                                />
                                            
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-row>
                            <v-row v-if="timeInputKind == 'auto'">
                                <v-container class="px-12">
                                    <v-card outlined class="pa-4">
                                    <v-row no-gutters>
                                        <v-col cols="8">
                                            <v-checkbox 
                                                label="Copri turno mattutino" 
                                                :disabled="!enableCoverMorningTimes"
                                                v-model="coverMorning"
                                            />
                                        </v-col>
                                        <v-divider vertical />
                                        <v-col class="d-flex align-center justify-center" cols="4">
                                            <div :class="{'grey--text text--darken-1': !coverMorning, 'grey--text text--darken-3': coverMorning}">
                                                {{coverMorningTimesLabel}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="8">
                                            <v-checkbox 
                                                label="Copri turno pomeridiano" 
                                                :disabled="!enableCoverAfternoonTimes"
                                                v-model="coverAfternoon"
                                            />
                                        </v-col>
                                        <v-divider vertical />
                                        <v-col class="d-flex align-center justify-center" cols="4">
                                            <div :class="{'grey--text text--darken-1': !coverAfternoon, 'grey--text text--darken-3': coverAfternoon}">
                                                {{coverAfternoonTimesLabel}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-container fluid class="px-12 mb-4">
                            <v-row class="mb-2">
                                <v-sheet
                                width="100%"
                                rounded="lg"
                                color="teal accent-1"
                                class="d-flex justify-center align-center"
                                >
                                <div class="text-h6">
                                    Permesso
                                </div>
                                </v-sheet>
                            </v-row>
                            <v-row>
                                <v-sheet
                                height="165"
                                width="100%"
                                rounded="lg"
                                color="grey lighten-4"
                                class="d-flex justify-center align-center"
                                >
                                <div class="text-h3">
                                    {{displayDuration}}
                                </div>
                                </v-sheet>
                            </v-row>
                            <v-row class="d-flex justify-center">
                                <v-checkbox
                                    v-model="apply104"
                                    label="Applica Legge 104"
                                    color="primary"
                                    hide-details
                                ></v-checkbox>
                            </v-row>
                            
                            </v-container>
                    </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                
                <v-card-actions class="justify-end align-end">
                    <v-btn
                        @click="progress -= 1"
                        v-if="progress > 1"
                        small
                    >  
                        <v-icon>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        @click="progress == 1 ? nextIfValidValue() : progress += 1"
                        v-if="progress < 2"
                        :disabled="!viableSteps[progress - 1]"
                        color="green accent-1"
                    >  
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                     <v-btn
                        color="green accent-2"
                        @click="emitCreationEvent"
                        v-if="progress >= 2"
                        class="px-4"
                    >
                        <v-icon left>
                            mdi-check
                        </v-icon>
                        Crea
                    </v-btn>
                </v-card-actions>
            </v-card>
        
    </v-dialog>
</template>
<script>
import { DateTime } from 'luxon'

export default {
    data: function() {
        return {
            progress: 1,
            absenceKind: null,
            timeInputKind: 'manual',
            coverMorning: false,
            coverAfternoon: false,
            manualHours: 0,
            manualMinutes: 0,
            apply104: false,
            minuteValueCheckRule: (val) => { return val % 30 == 0 || 'Valore non valido'; },
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        workDayScheduledTimes: {
            type: Object,
            default: function() {
                return {};
            }
        },
        lastClockRecord: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    methods: {
        cancelMethod: function() {
            this.$emit('close-absence-record-edit-dialog');
            this.resetState();
        },
        // confirmMethod () {
        //     this.$emit('c-dialog-confirm');
        // },
        checkMinValue: function() {
             return this.$refs.form.validate(); 
        },
        nextIfValidValue: function() {
            if(this.timeInputKind != 'manual') {
                this.progress += 1;
                return;
            }
            if (this.checkMinValue()) {
                this.progress += 1;
            }
        },
        selectAbsenceKind: function(selectedAbsenceKind) {
            this.absenceKind = selectedAbsenceKind;
            this.progress += 1;
        },
        resetState: function() {
            this.progress = 1;
            this.absenceKind = null;
            this.timeInputKind = 'manual';
            this.manualMinutes = 0;
            this.manualHours = 0;
            this.apply104 = false;
            this.coverMorning = false;
            this.coverAfternoon = false;
        },
        emitCreationEvent: function() {
            this.$emit('create-leave-record', this.computedAbsenceMinutes, this.apply104 );
            this.$emit('close-absence-record-edit-dialog');
            this.resetState();
        },
    },
    computed: {
        // generalizedLastClockRecord: function() {
        //     let js_punch_in_time = this.lastClockRecord.puncInTime ? new Date(this.lastClockRecord.punchInTime) : null;
        //     let js_punch_out_time = (this.lastClockRecord && this.lastClockRecord.punchOutRecord) ? new Date(this.lastClockRecord.punchOutRecord.punchTime) : null;
        //     return {
        //         generalizedPunchInTime: (js_punch_in_time ? DateTime.local(1970, 1, 1, js_punch_in_time.getHours(), js_punch_in_time.getMinutes(), 0) : null),
        //         generalizedPunchOutTime: (js_punch_out_time ? DateTime.local(1970, 1, 1, js_punch_out_time.getHours(), js_punch_out_time.getMinutes(), 0) : null),
        //     };
        // },
        displayDuration: function() {
            let hours = Math.floor(this.computedAbsenceMinutes / 60);
            let minutes = this.computedAbsenceMinutes % 60;
            let string = hours + ' Ore';
            if(minutes > 0)
                string += (' e ' + minutes + ' Minuti');
            return string;
        },
        computedAbsenceMinutes: function() {
            if(this.timeInputKind == 'auto') {
                let coveringTime = 0;
                if(this.coverMorning)
                    coveringTime += this.coverableMorningTime;
                if(this.coverAfternoon)
                    coveringTime += this.coverableAfternoonTime;
                return coveringTime;
            }
            if(this.timeInputKind == 'manual') {
                return (parseInt(this.manualHours) * 60) + parseInt(this.manualMinutes);
            }
            return 0;
        },
        viableSteps: function() {
            let check1 = this.computedAbsenceMinutes > 0;
            
            return [
                check1,
                false
            ]
        },
        enableCoverMorningTimes: function() {
            return this.workDayScheduledTimes.morningTimes && this.workDayScheduledTimes.morningTimes.clockInTime != null && this.workDayScheduledTimes.morningTimes.clockOutTime != null
        },
        enableCoverAfternoonTimes: function() {
            return this.workDayScheduledTimes.afternoonTimes && this.workDayScheduledTimes.afternoonTimes.clockInTime != null && this.workDayScheduledTimes.afternoonTimes.clockOutTime != null
        },
        coverableMorningTime: function() {
            if (this.workDayScheduledTimes.morningTimes.clockInTime && this.workDayScheduledTimes.morningTimes.clockOutTime) {
                let clockIn = DateTime.fromISO(this.workDayScheduledTimes.morningTimes.clockInTime)
                let clockOut = DateTime.fromISO(this.workDayScheduledTimes.morningTimes.clockOutTime)
                return Math.ceil(clockOut.diff(clockIn, "seconds").seconds / 60)
            } 
            return 0;
        },
        coverableAfternoonTime: function() {
            if (this.workDayScheduledTimes.afternoonTimes.clockInTime && this.workDayScheduledTimes.afternoonTimes.clockOutTime) {
                let clockIn = DateTime.fromISO(this.workDayScheduledTimes.afternoonTimes.clockInTime)
                let clockOut = DateTime.fromISO(this.workDayScheduledTimes.afternoonTimes.clockOutTime)
                return Math.ceil(clockOut.diff(clockIn, "seconds").seconds / 60)
            } 
            return 0;
        },
        coverMorningTimesLabel: function() {
            let coverableTimeString = Math.floor(this.coverableMorningTime / 60) + ' Ore ' + (this.coverableMorningTime % 60) + ' Minuti';
            return this.enableCoverMorningTimes ? coverableTimeString : '';
        },
        coverAfternoonTimesLabel: function() {
            let coverableTimeString = Math.floor(this.coverableAfternoonTime / 60) + ' Ore ' + (this.coverableAfternoonTime % 60) + ' Minuti';
            return this.enableCoverAfternoonTimes ? coverableTimeString : '';
        }
    }
}
</script>